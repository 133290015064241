import React, { useState } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"

import { rhythm } from "../utils/typography"
import styles from "./layout.module.css"
import { LeaderBoard, Native } from "./Ad"

const Layout = ({ children }) => {
  const [showMenu, setShowMenu] = useState(false)
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          siteUrl
          title
        }
      }
    }
  `)

  const toggleMenu = () => setShowMenu(!showMenu)

  return (
    <>
      <div className={styles.toggle} onClick={toggleMenu} onKeyDown={toggleMenu}
           role="button"
           tabIndex={0}
      >{showMenu ? "×" : "☰"}</div>
      {!showMenu && (<Link to={"/"}>
        <img src={"/lottolibrary.png"} alt={"LottoLibrary.com"} className={styles.logo} />
      </Link>)}
      <div className={styles.search}>
        <div className="gcse-search" />
      </div>
      <nav className={showMenu ? styles.showNav : styles.nav}>
        <Link to={"/"} className={styles.navItem}>Blog</Link>
        <Link to={"/calculators"} className={styles.navItem}>Tools And Calculators</Link>
        <Link to={"/books"} className={styles.navItem}>Recommended Books</Link>
      </nav>
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(32),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
        <LeaderBoard />
        <main>{children}</main>
        <Native />
        <footer style={{ textAlign: "center" }}>
          © <Link
          to={"/"}
        >{data.site.siteMetadata.title}</Link>
          {` `}
          {new Date().getFullYear()} | All rights reserved
          <br />
          <Link to="/about/">About</Link>
          <br />
          <Link to="/terms-and-conditions/">Terms &amp; Conditions</Link>
          <br />
          <Link to="/privacy-policy/">Privacy policy</Link>{` `}
          <br />
          <br />
        </footer>
      </div>
    </>
  )
}

export default Layout
